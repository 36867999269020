<template>
  <b-form class="form" v-on:submit.prevent="save">
    <b-alert show="" variant="light">
      Click <strong>Submit</strong> to confirm update the Holiday
    </b-alert>
    <!-- begin: from date  -->
    <b-form-group
      label-for="holiday-from_date-input"
      label-cols-lg="2"
      :label="$t('HOLIDAY.START')"
    >
      <b-form-datepicker
        id="holiday-from_date-input"
        class="form-control h-auto"
        v-model="$v.form.from_date.$model"
        :state="validateState('from_date')"
      >
      </b-form-datepicker>
    </b-form-group>
    <!-- end:from date  -->
    <!-- begin:to date  -->
    <b-form-group
      label-for="holiday-to_date-input"
      label-cols-lg="2"
      :label="$t('HOLIDAY.END')"
    >
      <b-form-datepicker
        id="holiday-to_date-input"
        class="form-control h-auto"
        v-model="$v.form.to_date.$model"
        :state="validateState('to_date')"
      >
      </b-form-datepicker>
    </b-form-group>
    <!-- end:to date  -->

    <!-- begin: Holiday     -->
    <b-form-group
      label-class="text-bolder"
      label-cols-lg="2"
      :label="$t('HOLIDAY.HOLIDAY')"
    >
      <b-form-input
        id="holiday"
        class="form-control h-auto"
        v-model="$v.form.holiday.$model"
        :state="validateState('holiday')"
        :placeholder="$t('HOLIDAY.HOLIDAY1')"
      >
      </b-form-input>
    </b-form-group>
    <!-- end: Holiday     -->

    <!-- begin:remark  -->
    <b-form-group
      label-for="holiday-desc-input"
      label-cols-lg="2"
      :label="$t('JOB.REMARK')"
    >
      <b-form-textarea
        id="holiday-desc-input"
        class="form-control h-auto"
        :placeholder="$t('JOB.REMARK')"
        rows="3"
        max-rows="6"
        v-model="$v.form.remark.$model"
        :state="validateState('remark')"
      >
      </b-form-textarea>
    </b-form-group>
    <!-- end:remark  -->
    <div class="d-flex justify-content-end border-top pt-3">
      <b-button
        ref="employee-general-button"
        variant="primary"
        type="submit"
        class="font-size-lg font-weight-bold"
        v-bind:class="{ 'spinner spinner-right spinner-light': isBusy }"
      >
        {{ $t("EMPLOYEES.DETAILS.SUBMIT") }}
      </b-button>
    </div>
  </b-form>
</template>

<script>
import { validationMixin } from "vuelidate";
import { maxLength, required } from "vuelidate/lib/validators";

export default {
  name: "HolidaySettingsEditForm",
  mixins: [validationMixin],
  props: {
    holiday: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        from_date: this.holiday.from_date,
        to_date: this.holiday.to_date,
        holiday: this.holiday.name,
        remark: this.holiday.description,
      },
      isBusy: false,
    };
  },
  validations: {
    form: {
      from_date: {
        required,
      },
      to_date: {
        required,
      },
      holiday: {
        required,
      },
      remark: {
        required,
        maxLength: maxLength(2000),
      },
    },
  },
  methods: {
    /**
     * Validate form.
     *
     * @param name
     * @returns {boolean|null}
     */
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    /**
     * Attempts to save the form.
     */
    save() {
      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        return;
      }

      const submitForm = {
        id: this.holiday.id,
        name: this.form.holiday,
        description: this.form.remark,
        from_date: this.form.from_date,
        to_date: this.form.to_date,
      };

      this.isBusy = true;
      this.$store
        .dispatch("updateSchoolHoliday", submitForm)
        .then((response) => {
          this.$emit("form-success", response.data);
        })
        .catch(() => this.$emit("form-failure"))
        .finally(() => (this.isBusy = false));
    },
    close(response) {
      return response;
    },
  },
};
</script>

<style scoped></style>
