<template>
  <div class="d-flex flex-column">
    <h4 class="font-weight-bold py-5">{{ title }}</h4>
    <b-form class="form" v-on:submit.prevent="saveHoliday">
      <!-- begin: from date  -->
      <b-form-group
        :label="$t('HOLIDAY.START')"
        label-for="holiday-from_date-input"
      >
        <b-form-datepicker
          id="holiday-from_date-input"
          class="form-control h-auto"
          v-model="$v.form.from_date.$model"
          :state="validateState('from_date')"
        >
        </b-form-datepicker>
      </b-form-group>
      <!-- end:from date  -->

      <!-- begin:to date  -->
      <b-form-group
        label-for="holiday-to_date-input"
        :label="$t('HOLIDAY.END')"
      >
        <b-form-datepicker
          id="holiday-to_date-input"
          class="form-control h-auto"
          v-model="$v.form.to_date.$model"
          :state="validateState('to_date')"
        >
        </b-form-datepicker>
      </b-form-group>
      <!-- end:to date  -->

      <!-- begin: Holiday     -->
      <b-form-group label-class="text-bolder" :label="$t('HOLIDAY.HOLIDAY')">
        <b-form-input
          id="holiday"
          class="form-control h-auto"
          v-model="$v.form.holiday.$model"
          :state="validateState('holiday')"
          :placeholder="$t('HOLIDAY.HOLIDAY1')"
        >
        </b-form-input>
      </b-form-group>
      <!-- end: Holiday     -->

      <!-- begin:remark  -->
      <b-form-group label-for="holiday-desc-input" :label="$t('JOB.REMARK')">
        <b-form-textarea
          id="holiday-desc-input"
          class="form-control h-auto"
          :placeholder="$t('JOB.REMARK')"
          rows="3"
          max-rows="6"
          v-model="$v.form.remark.$model"
          :state="validateState('remark')"
        >
        </b-form-textarea>
      </b-form-group>
      <!-- end:remark  -->

      <!--begin::Action-->
      <div class="form-group d-flex flex-column">
        <button
          type="submit"
          ref="holiday-form-submit"
          class="btn btn-primary font-weight-bolder"
          v-bind:class="{ 'spinner spinner-light spinner-right': isBusy }"
        >
          {{ $t("COMPANY.REGISTRATION.SUBMIT") }}
        </button>
      </div>
      <!--end::Action-->
    </b-form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { maxLength, required } from "vuelidate/lib/validators";
import { mapState } from "vuex";

export default {
  name: "HolidaySettingsForm",
  mixins: [validationMixin],
  props: {
    title: {
      type: String,
      default: "Holiday Form",
    },
  },
  data() {
    return {
      form: {
        from_date: "",
        to_date: "",
        holiday: "",
        remark: "",
      },
      isBusy: false,
    };
  },
  validations: {
    form: {
      from_date: {
        required,
      },
      to_date: {
        required,
      },
      holiday: {
        required,
      },
      remark: {
        required,
        maxLength: maxLength(2000),
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    saveHoliday() {
      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        return;
      }

      const from_date = this.$v.form.from_date.$model;
      const to_date = this.$v.form.to_date.$model;
      const holiday = this.$v.form.holiday.$model;
      const remark = this.$v.form.remark.$model;

      this.isBusy = true;
      this.$store
        .dispatch("registerSchoolHoliday", {
          from_date,
          to_date,
          name: holiday,
          description: remark,
        })
        .then((response) => {
          this.$emit("close", response.data);
        })
        .finally(() => (this.isBusy = false));
    },
    close(response) {
      return response;
    },
  },
  computed: {
    ...mapState({
      items: (state) => state.CompanyModule.holiday.form.response,
    }),
  },
};
</script>

<style scoped></style>
