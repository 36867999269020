<template>
  <b-modal
    id="common-employee-details-modal"
    title="Manage Holiday"
    hide-footer
  >
    <HolidaySettingsEditForm
      :holiday="selectedHoliday"
      @form-success="handleSuccess"
      @form-failure="handleFailure"
    ></HolidaySettingsEditForm>
  </b-modal>
</template>

<script>
import HolidaySettingsEditForm from "@/modules/school/components/holiday/HolidaySettingsEditForm";

export default {
  name: "HolidaySettingsModal",
  components: { HolidaySettingsEditForm },
  props: {
    title: {
      String,
    },
    selectedHoliday: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isBusy: false,
    };
  },

  mounted() {
    console.log("Mounted", this.selectedHoliday);
  },

  methods: {
    /**
     * Handle successful form submission
     */
    handleSuccess() {
      this.$bvModal.msgBoxOk(this.$t("HOLIDAY.Edit.Success"));
    },

    /**
     * Handles form failure.
     */
    handleFailure() {
      this.$bvModal.msgBoxOk(this.$t("HOLIDAY.Edit.Failure"), {
        okVariant: "danger",
      });
    },
  },
};
</script>

<style scoped></style>
