<template>
  <div>
    <HolidaySettingsTableControl class="mb-3"></HolidaySettingsTableControl>

    <AppPageCard>
      <template #card-title>
        {{ $t("SIDEBAR.HOLIDAY") }}
      </template>

      <template #card-toolbar>
        <div
          class="btn btn-icon btn-light btn-hover-primary mr-3"
          v-on:click="refresh"
          v-b-tooltip.hover
          :title="$t('COMMON.TOOLTIP.REFRESH')"
        >
          <span class="svg-icon svg-icon-lg svg-icon-primary">
            <inline-svg src="/media/svg/icons/Media/Repeat.svg" />
          </span>
        </div>

        <b-button variant="light-primary" v-b-toggle.holiday-list-sidebar>{{
          $t("DEPARTMENT.BUTTON")
        }}</b-button>
      </template>

      <HolidaySettingsTable :reload="reload"></HolidaySettingsTable>

      <b-sidebar
        ref="holiday-list-sidebar-ref"
        id="holiday-list-sidebar"
        backdrop
        right
        lazy
        :title="$t('HOLIDAY.HOLIDAY')"
        sidebar-class="offcanvas"
        header-class="offcanvas-header d-flex mt-5 ml-5 mr-5"
        bg-variant="white"
      >
        <template v-slot:header-close>
          <button
            class="btn btn-sm btn-icon btn-light-secondary btn-hover-danger"
          >
            <i class="ki ki-close icon-xs"></i>
          </button>
        </template>
        <div class="px-10 mt-5">
          <HolidaySettingsForm
            v-slot:default="{}"
            :title="$t('HOLIDAY.CREATE')"
            v-on:close="close"
          ></HolidaySettingsForm>
        </div>
      </b-sidebar>
    </AppPageCard>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import HolidaySettingsForm from "@/modules/school/components/holiday/HolidaySettingsForm";
import HolidaySettingsTable from "@/modules/school/components/holiday/HolidaySettingsTable";
import AppPageCard from "@/modules/core/components/shared/card/AppPageCard";
import HolidaySettingsTableControl from "@/modules/school/components/holiday/HolidaySettingsTableControl";

export default {
  name: "HolidayListPage",
  components: {
    HolidaySettingsTableControl,
    AppPageCard,
    HolidaySettingsTable,
    HolidaySettingsForm,
  },
  mounted() {
    console.log("Holiday List");
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("SIDEBAR.COMPANY") },
      {
        title: this.$t("SIDEBAR.DEPARTMENT"),
        route: "/education/holiday/list",
      },
    ]);
  },
  data() {
    return {
      formData: "",
      reload: false,
    };
  },
  methods: {
    /**
     * Close modal.
     *
     * @param data
     */
    close(data) {
      console.log(data);
      this.formData = "Successfully added holiday";
      console.log(this.formData);

      const sideBar = this.$refs["holiday-list-sidebar-ref"];
      sideBar.hide();

      this.refresh();
    },

    refresh() {
      this.reload = !this.reload;
    },
  },
};
</script>

<style scoped></style>
