<template>
  <div>
    <b-table
      :fields="fields"
      :items="items"
      :busy="isBusy"
      ref="holiday-settings-table"
      table-class="table-head-custom table-vertical-center"
      @row-clicked="showHoliday"
      responsive
      hover
      show-empty
    >
      <!-- begin: t-head -->
      <template v-slot:head(index)="data">
        <span class="text-dark-75">{{ data.label.toUpperCase() }}</span>
      </template>
      <!-- end: t-head -->

      <!-- begin: t-row -->
      <template v-slot:cell(index)="data">
        <span class="font-weight-bolder">{{ data.index + 1 }}</span>
      </template>
      <!-- end: t-row -->

      <!--  begin:: actions    -->
      <template v-slot:cell(actions)="data">
        <div class="d-flex flex-row justify-content-left">
          <!-- begin::: edit-details button -->
          <button
            class="btn btn-icon btn-light btn-hover-primary mr-3"
            v-on:click="showHoliday(data.item)"
            v-b-tooltip.hover
            :title="$t('HOLIDAY.TOOLTIP.Edit')"
          >
            <span class="svg-icon svg-icon-primary menu-icon">
              <inline-svg src="/media/svg/icons/Communication/Write.svg" />
            </span>
          </button>
          <!-- end::: edit-details button -->
          <button
            class="btn btn-icon btn-light btn-hover-primary mr-3"
            v-on:click="deleteSchoolHoliday(data.item)"
            v-b-tooltip.hover
            :title="$t('HOLIDAY.TOOLTIP.Delete')"
          >
            <span class="svg-icon svg-icon-gray menu-icon">
              <inline-svg src="/media/svg/icons/General/Trash.svg" />
            </span>
          </button>
          <!-- end::: delete-holiday button -->
        </div>
      </template>
      <!--  end:: actions    -->
      <template #table-busy>
        <AppLoader></AppLoader>
      </template>

      <template #empty>
        <AppEmptyList
          :text-top="$t('HOLIDAY.Empty.Title')"
          :text-bottom="$t('HOLIDAY.Empty.Subtitle_Primary')"
        ></AppEmptyList>
      </template>
    </b-table>

    <b-pagination
      v-if="items.length"
      pills
      size="lg"
      align="right"
      v-model="pagination.current_page"
      :total-rows="pagination.total"
      :per-page="pagination.per_page"
      @change="changePage"
    ></b-pagination>

    <HolidaySettingsModal
      title="Manage Holiday"
      :selected-holiday="selectedHoliday"
    ></HolidaySettingsModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AppLoader from "@/modules/core/components/loaders/AppLoader";
import AppEmptyList from "@/modules/core/components/empty/AppEmptyList";
import HolidaySettingsModal from "@/modules/school/components/holiday/HolidaySettingsModal";
import dayjs from "dayjs";

export default {
  name: "HolidaySettingsTable",
  components: { HolidaySettingsModal, AppLoader, AppEmptyList },
  props: ["reload"],

  data() {
    return {
      fields: [
        { key: "index", label: "#" },
        {
          key: "name",
          label: this.$t("HOLIDAY.HOLIDAY"),
          sortable: true,
          class: "font-weight-bolder",
        },
        {
          key: "from_date",
          label: this.$t("HOLIDAY.START"),
          sortable: true,
          formatter: (date) => {
            return dayjs(date).format("DD/MM/YYYY");
          },
        },
        {
          key: "to_date",
          label: this.$t("HOLIDAY.END"),
          sortable: true,
          formatter: (date) => {
            return dayjs(date).format("DD/MM/YYYY");
          },
        },
        { key: "actions", label: this.$t("EMPLOYEETABLE.ACTIONS") },
      ],
      isBusy: false,
      selectedHoliday: {},
    };
  },

  mounted() {
    this.fetch();
  },

  methods: {
    /**
     * Fetch school holiday list from Store.
     *
     * @returns {Promise<boolean>}
     */
    async fetch() {
      this.isBusy = true;

      try {
        const res = await this.$store.dispatch("fetchSchoolHolidayList");

        console.log("Res is ", res.data);
      } catch (err) {
        console.error(err);
      } finally {
        this.isBusy = false;
      }
    },

    /**
     * Refresh the table.
     *
     * Just call the fetch table.
     */
    refresh() {
      console.log("Reload Table!");
      this.fetch();
    },

    /**
     * Change holiday page.
     *
     * @param page
     */
    changePage(page) {
      console.log("Change page to ", page);
      this.$store.commit("setSchoolHolidayListPage", page);
      this.fetch();
    },

    /**
     * Delete school holiday.
     *
     * @param row
     */
    deleteSchoolHoliday(row) {
      console.log("Selected: ", row.name);

      this.$bvModal
        .msgBoxConfirm(
          this.$t("HOLIDAY.Delete.Subtitle", { holiday: row.name }),
          {
            title: this.$t("ALERT.SURE"),
            centered: true,
            cancelVariant: "light",
          }
        )
        .then((value) => {
          console.log("Hmmm", value);
          if (value) {
            this.$store
              .dispatch("deleteSchoolHoliday", row.id)
              .then(() => {
                this.$bvModal.msgBoxOk(
                  this.$t("HOLIDAY.Delete.Success", { holiday: row.name }),
                  {
                    centered: true,
                  }
                );
                this.refresh();
              })
              .catch(() => {
                console.error("Some issues bro");
                this.$bvModal.msgBoxOk("Failed!!", {
                  centered: true,
                  okVariant: "danger",
                });
              });
          }
        })
        .catch((err) => {
          console.error("Some issues bro", err);
        });
    },

    /**
     * Show the holiday modal or component.
     *
     * @param item
     */
    showHoliday(item) {
      console.log("Selected holiday ", item);

      this.selectedHoliday = item;

      this.$bvModal.show("common-employee-details-modal");
    },
  },
  watch: {
    reload() {
      this.fetch();
    },
  },
  computed: {
    ...mapGetters({
      items: "getSchoolHolidayList",
      pagination: "getSchoolHolidayListPagination",
      currentPage: "getSchoolHolidayListPage",
    }),
  },
};
</script>

<style scoped></style>
