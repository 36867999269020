<template>
  <b-card class="card-custom" body-class="p-3">
    <!-- begin:: Main Flexbox  -->
    <div class="mx-3 d-flex justify-content-between">
      <div class="flex-grow-1 mr-3">
        <b-form-input
          v-model="query"
          :placeholder="$t('HOLIDAY.Filter.Name')"
          debounce="500"
        ></b-form-input>
      </div>

      <!-- begin::: buttons  -->
      <div>
        <b-button
          class="btn btn-icon btn-light btn-hover-primary mr-3"
          @click="fetch"
        >
          <span class="svg-icon svg-icon-2x svg-icon-primary">
            <inline-svg src="/media/svg/icons/General/Search.svg" />
          </span>
        </b-button>

        <b-button
          class="btn btn-icon btn-light btn-hover-primary mr-3"
          v-b-toggle.collapse-1
          :pressed="isCollapsed"
        >
          <span class="svg-icon svg-icon-2x svg-icon-primary">
            <inline-svg src="/media/svg/icons/Text/Filter.svg" />
          </span>
        </b-button>

        <b-dropdown
          class="btn btn-icon btn-light btn-hover-primary mr-3"
          no-caret
          variant="link"
          right
        >
          <template #button-content>
            <span class="svg-icon svg-icon-2x svg-icon-primary">
              <inline-svg src="/media/svg/icons/Shopping/Settings.svg" />
            </span>
          </template>

          <b-dropdown-header>
            {{ $t("COMMON.Table.Actions.Base") }}
          </b-dropdown-header>

          <b-dropdown-item @click="resetFilters">
            <span class="symbol symbol-50 mr-6">
              <inline-svg src="/media/svg/icons/Shopping/Settings.svg" />
            </span>
            {{ $t("COMMON.Table.Actions.Reset_Filters") }}
          </b-dropdown-item>

          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-header> Files</b-dropdown-header>

          <b-dropdown-item @click="console.log('test')">
            <span class="symbol symbol-50 mr-6">
              <inline-svg
                src="/media/svg/icons/Communication/Clipboard-list.svg"
              />
            </span>
            {{ $t("COMMON.Table.Actions.Export_Excel") }}
          </b-dropdown-item>

          <b-dropdown-item @click="console.log('test')">
            <span class="symbol symbol-50 mr-6">
              <inline-svg src="/media/svg/icons/Files/Export.svg" />
            </span>
            Export - PDF
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <!-- end::: buttons  -->
    </div>
    <!-- end:: Main Flexbox  -->

    <b-collapse id="collapse-1" class="px-5 pt-4" v-model="isCollapsed">
      <div class="d-flex">
        <!-- begin: From Date     -->
        <b-form-group class="mr-4" label-class="text-bolder" label="From Date">
          <b-form-datepicker
            v-model="from_date"
            id="from-date"
            class="form-control h-auto"
            @input="watchStartDate"
          >
          </b-form-datepicker>
        </b-form-group>

        <!-- begin: Holiday     -->
        <b-form-group label-class="text-bolder" label="To Date">
          <b-form-datepicker
            v-model="to_date"
            id="to-date"
            class="form-control h-auto"
            @input="watchEndDate"
          >
          </b-form-datepicker>
        </b-form-group>
      </div>
    </b-collapse>
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";
import dayjs from "dayjs";

export default {
  name: "HolidaySettingsTableControl",
  data() {
    return {
      query: null,
      from_date: null,
      to_date: null,
      isCollapsed: false,
    };
  },
  beforeDestroy() {
    this.resetFilters();
  },

  methods: {
    /**
     * Reset from date and to date filters.
     */
    resetFilters() {
      this.to_date = null;
      this.from_date = null;

      const changedFilters = this.filters;
      console.log("Changed filters", changedFilters);

      changedFilters.to_date = this.to_date;
      changedFilters.from_date = this.from_date;

      this.$store.dispatch("changeSchoolHolidayListFilters", {
        filters: changedFilters,
      });

      this.fetch();
    },

    /**
     * Watch start date input.
     *
     * @param date
     */
    watchStartDate(date) {
      console.log(date);

      const changedFilters = this.filters;
      console.log("Changed filters", changedFilters);

      changedFilters.from_date = dayjs(date).format();

      this.$store.dispatch("changeSchoolHolidayListFilters", {
        filters: changedFilters,
      });
    },

    /**
     * Watch start date input.
     *
     * @param date
     */
    watchEndDate(date) {
      console.log(date);

      const changedFilters = this.filters;
      console.log("Changed filters", changedFilters);

      changedFilters.to_date = dayjs(date).format();

      this.$store.dispatch("changeSchoolHolidayListFilters", {
        filters: changedFilters,
      });
    },

    /**
     * Fetch list
     */
    fetch() {
      this.$store.dispatch("fetchSchoolHolidayList");
    },
  },

  watch: {
    query(newQuery) {
      console.log(newQuery);

      const changedFilters = this.filters;
      console.log("Changed filters", changedFilters);

      changedFilters.name = newQuery;

      this.$store.dispatch("changeSchoolHolidayListFilters", {
        filters: changedFilters,
      });
      this.$store.dispatch("fetchSchoolHolidayList");
    },
  },

  computed: {
    ...mapGetters({
      filters: "getSchoolHolidayListFilters",
    }),
  },
};
</script>

<style scoped></style>
